import { styled } from '@/stitches.config';

export const Img = styled('img', {
  maxInlineSize: '100%',
  blockSize: 'auto',
});

export const Picture = styled('picture', {
  display: 'inline-block',
  overflow: 'hidden',
  variants: {
    loadEffect: {
      none: {},
      fade: {
        img: {
          opacity: '0',
          transition:
            'opacity 1s var(--transition-easing), transform 1s var(--transition-easing)',
        },
        '&.is-loaded': {
          img: {
            opacity: '1',
          },
        },
      },
    },
    hoverEffect: {
      zoom: {
        cursor: 'pointer',
        img: {
          transition:
            'opacity 1s var(--transition-easing), transform 1s var(--transition-easing)',
        },
        '@hover': {
          '&:hover, [data-hover]:hover &': {
            img: {
              transform: 'scale(1.05)',
            },
          },
        },
      },
    },
    layout: {
      fill: {
        display: 'block',
        '&, img': {
          position: 'absolute',
          inset: '0px',
        },
        '&': {
          width: 'initial',
          height: 'initial',
        },
        img: {
          width: '0px',
          height: '0px',
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
        },
      },
      fixed: {
        position: 'relative',
        display: 'inline-block',
        img: {
          maxWidth: 'none',
        },
      },
      responsive: {
        position: 'relative',
        display: 'block',
        width: '100%',
        img: {
          position: 'absolute',
          inset: '0px',
          width: '0px',
          height: '0px',
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
        },
      },
    },
    objectFit: {
      cover: {
        img: {
          objectFit: 'cover',
        },
      },
      contain: {
        img: {
          objectFit: 'contain',
        },
      },
      none: {
        img: {
          objectFit: 'none',
        },
      },
    },
    objectPosition: {
      center: {
        img: {
          objectPosition: 'center',
        },
      },
      top: {
        img: {
          objectPosition: 'top',
        },
      },
      bottom: {
        img: {
          objectPosition: 'bottom',
        },
      },
      left: {
        img: {
          objectPosition: 'left',
        },
      },
      right: {
        img: {
          objectPosition: 'right',
        },
      },
      'top-left': {
        img: {
          objectPosition: 'top left',
        },
      },
      'top-right': {
        img: {
          objectPosition: 'top right',
        },
      },
      'bottom-left': {
        img: {
          objectPosition: 'bottom left',
        },
      },
      'bottom-right': {
        img: {
          objectPosition: 'bottom right',
        },
      },
      'left-top': {
        img: {
          objectPosition: 'left top',
        },
      },
      'left-bottom': {
        img: {
          objectPosition: 'left bottom',
        },
      },
      'right-top': {
        img: {
          objectPosition: 'right top',
        },
      },
      'right-bottom': {
        img: {
          objectPosition: 'right bottom',
        },
      },
    },
    aspectRatio: {
      '1by1': {
        aspectRatio: '1 / 1',
      },
      '1by2': {
        aspectRatio: '1 / 2',
      },
      '2by1': {
        aspectRatio: '2 / 1',
      },
      '2by3': {
        aspectRatio: '2 / 3',
      },
      '3by2': {
        aspectRatio: '3 / 2',
      },
      '3by4': {
        aspectRatio: '3 / 4',
      },
      '4by3': {
        aspectRatio: '4 / 3',
      },
      '4by5': {
        aspectRatio: '4 / 5',
      },
      '5by4': {
        aspectRatio: '5 / 4',
      },
      '9by16': {
        aspectRatio: '9 / 16',
      },
      '16by9': {
        aspectRatio: '16 / 9',
      },
      '21by9': {
        aspectRatio: '21 / 9',
      },
      '8by3': {
        aspectRatio: '8 / 3',
      },
    },
  },
});
