import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type HTMLElementOrNull = HTMLElement | null;
export type RefElementOrNull<T> = T | null;
export type CallbackRef = (node: HTMLElementOrNull) => unknown;
export type AnyRef = CallbackRef | MutableRefObject<HTMLElementOrNull>;

const config: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: 0,
};

interface UseInViewRefOptions extends IntersectionObserverInit {
  root?: HTMLElementOrNull;
}

export function useInViewRef(
  options?: UseInViewRefOptions
): [CallbackRef, boolean] {
  const { root, rootMargin, threshold } = { ...config, ...options };

  const [node, setNode] = useState<HTMLElementOrNull>(null);
  const [inView, setInView] = useState<boolean>(false);

  useEffect(() => {
    if (node) {
      const observer = new IntersectionObserver(
        (entries, observerRef) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
              observerRef.unobserve(node);
            }
          });
        },
        {
          root,
          rootMargin,
          threshold,
        }
      );

      observer.observe(node);
      return () => {
        observer.disconnect();
      };
    }
  }, [node, root, rootMargin, threshold]);

  const ref = useCallback((node: HTMLElementOrNull) => {
    setNode(node);
  }, []);

  return [ref, inView];
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      setWindowSize({
        width: vw,
        height: vh,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export function useHover() {
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    setIsHover(window.matchMedia('(hover: hover)').matches);
  }, []);
  return isHover;
}

export function getRefValue<C>(ref: RefObject<C>) {
  return ref.current as C;
}

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

export function useDimensions(data = null, liveMeasure = true) {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [node, data]);

  return [ref, dimensions, node];
}

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const checkTouchDevice = () => {
      setIsTouchDevice(window.matchMedia('(pointer: coarse)').matches);
    };
    window.addEventListener('resize', checkTouchDevice);

    // Initial check
    checkTouchDevice();

    return () => {
      window.removeEventListener('resize', checkTouchDevice);
    };
  }, []);

  return isTouchDevice;
};

export default useIsTouchDevice;
