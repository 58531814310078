import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DEFAULT_IMAGE,
  ImageProps,
} from '@/components/shared/media/image/Image.props';
import { Img, Picture } from '@/components/shared/media/image/Image.styles';
import {
  createImageQueryParams,
  createPreloadLinks,
  createSourceElement,
  determineImageSrc,
} from '@/components/shared/media/image/Image.utils';
import { Skeleton } from '@/components/shared/media/image/skeleton';
import { useInViewRef } from '@/components/shared/utility/hooks';

export const Image = ({
  css,
  className = '',
  src,
  alt,
  width,
  height,
  sources,
  placeholder,
  loading = 'lazy',
  loadEffect = 'fade',
  priority = false,
  layout,
  objectFit = 'cover',
  objectPosition = 'center',
  hoverEffect,
  rootRef = null,
  rootMargin = '0px 0px 0px 0px',
  aspectRatio,
  isMaxWidth,
  onLoad,
}: ImageProps) => {
  if (priority) {
    loading = 'eager';
    loadEffect = 'none';
  }

  const [isLoaded, setIsLoaded] = useState(priority);
  const [fadeOutSkeleton, setFadeOutSkeleton] = useState(false);
  const [ref, inView] = useInViewRef({
    root: rootRef,
    rootMargin: rootMargin,
    threshold: 0.01,
  });

  const memoizedSrc = useMemo(
    () =>
      determineImageSrc(
        createImageQueryParams(src),
        DEFAULT_IMAGE,
        inView,
        loading
      ),
    [src, inView, loading]
  );

  const preloadLinks = useMemo(() => {
    return priority
      ? createPreloadLinks(createImageQueryParams(src), sources)
      : null;
  }, []);

  const handleImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      if (event.currentTarget.src === DEFAULT_IMAGE) return;
      setIsLoaded(true);
      if (onLoad) {
        onLoad();
      }
    },
    [onLoad]
  );

  useEffect(() => {
    if (isLoaded) {
      setFadeOutSkeleton(true);
    }
  }, [isLoaded]);

  if (!src) {
    return null;
  }

  return (
    <>
      {preloadLinks}
      <Picture
        ref={ref as React.Ref<HTMLPictureElement>}
        loadEffect={loadEffect}
        layout={layout}
        aspectRatio={aspectRatio}
        objectPosition={objectPosition}
        objectFit={objectFit}
        hoverEffect={hoverEffect}
        className={`${className} ${isLoaded ? 'is-loaded' : ''}`}
        css={{
          ...(layout === 'responsive' &&
            aspectRatio === undefined && {
              aspectRatio: `${width} / ${height}`,
            }),
          ...(isMaxWidth && {
            maxWidth: `${width}px`,
          }),
          ...css,
        }}
        data-test="image"
      >
        {placeholder && (
          <Skeleton
            placeholder={placeholder}
            fadeIn={inView}
            fadeOut={fadeOutSkeleton}
          />
        )}
        {sources?.map((source, index) =>
          createSourceElement(source, index, inView, loading)
        )}
        <Img
          src={memoizedSrc}
          alt={alt}
          width={width}
          height={height}
          onLoad={handleImageLoad}
        />
      </Picture>
    </>
  );
};
